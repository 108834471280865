/* eslint-disable prettier/prettier */
import { i18n } from "./i18n";
import "@wealthsimple/canadian-bank-account/dist/canadian-bank-account.js";

function isDate(val) {
  if (
    new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/).test(val)
  ) {
    return !isNaN(Date.parse(val));
  }
  return false;
}
function formatDate(d) {
  let month = (d.getUTCMonth() + 1).toString();
  if (month.length === 1) {
    month = "0" + month;
  }
  let day = d.getUTCDate().toString();
  if (day.length === 1) {
    day = "0" + day;
  }
  return `${d.getUTCFullYear()}-${month}-${day}`;
}
const validationRules = {
  validDate: (v) => {
    if (v === undefined || v === null || v === "") {
      return true;
    }
    return isDate(v) || i18n.t("validation.not_valid_date");
  },
  dateOfBirth: (v) => {
    if (v === undefined || v === null || v === "") {
      return true;
    }
    const d2 = new Date();
    if (!isDate(v)) {
      return i18n.t("validation.date_lower_{0}", [formatDate(d2)]);
    }
    const n = Date.parse(v);
    const d = new Date(n);

    return d <= d2 || i18n.t("validation.date_lower_{0}", [formatDate(d2)]);
  },
  streetNumber: (v) =>
    !v ||
    /^[a-zA-Z0-9]+([(\-| )][a-zA-Z0-9]+)*$/.test(v) ||
    i18n.t("validation.must_be_a_valid_street_number"),
  required: (v) => !!v || i18n.t("validation.required"),

  requiredNum: (v) => !!v || v === 0 || i18n.t("validation.required"),

  requiredMinMax: (min, max) =>
    !!min || !!max || min === 0 || i18n.t("validation.minmaxrequired"),

  integer: (v) =>
    !v || /^([1-9]\d*|0)$/.test(v) || i18n.t("validation.must_be_integer"),

  integer2: (v) =>
    !v || !isNaN(parseInt(v)) || i18n.t("validation.must_be_integer"),

  requiredInteger: (v) =>
    (!!v && /^(-?[1-9]\d*|0)$/.test(v)) || v === 0 || "Must be integer",

  postalCode: (v) =>
    /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/.test(
      v,
    ) || i18n.t("validation.must_be_a_valid_postal_code"),

  zipCode: (v) =>
    /^\d{5}(?:[-\s]\d{4})?$/.test(v) ||
    i18n.t("validation.must_be_a_valid_zip_code"),

  email: (v) =>
    !v || // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(v).toLowerCase(),
    ) ||
    i18n.t("validation.email_must_be_valid"),

  notZeroMoney: (v) =>
    !v ||
    parseFloat(v) > 0 ||
    i18n.t("validation.must_be_more_than_zero_money"),

  decimal: (v) =>
    !v || !isNaN(parseFloat(v)) || i18n.t("validation.must_be_decimal"),

  decimal2: (v) =>
    /^\d+(\.\d{1,2})?$/.test(v) || i18n.t("validation.must_be_decimal"),

  phone: (v) =>
    !v ||
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || //var formattedPhoneNumber = subjectString.replace(phoneRegex, "($1) $2-$3");
    i18n.t("validation.must_be_a_valid_phone_number"),

  validName: (v) => {
    return (
      !v ||
      /^([a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ.]+)(([a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ.])?([ '-\\.])?)*$/.test(
        v,
      ) ||
      i18n.t("validation.enter_a_valid_name")
    );
  },

  validMiddleName: (v) =>
    !v ||
    /^(([a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+)([ '-])?)+([a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*\.?)$/.test(
      v,
    ) ||
    i18n.t("validation.enter_a_valid_name"),

  validLastName: (v) =>
    !v ||
    /^\.|(([a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ.]+)([ '-])?)+([a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ.]*\.?)$/.test(
      v,
    ) ||
    i18n.t("validation.enter_a_valid_name"),

  equalOrLessThan0: (v) => {
    const i = parseInt(v);
    return (
      (!isNaN(i) && i <= 0) || i18n.t("validation.must_be_equal_or_less_than_0")
    );
  },

  alphanumeric: (v) =>
    !v ||
    /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\d]+$/.test(v) ||
    i18n.t("validation.alphanumeric"),

  atLeastOne: (v) => {
    // console.log("at least one: ", v);
    return (
      (!!v && v.filter((o) => o !== null && o != undefined).length > 0) ||
      i18n.t("validation.at_least_one_is_required")
    );
  },

  atLeastOnePlan: (v) =>
    (!!v && v.length > 0) || i18n.t("validation.at_least_one_plan_is_required"),

  atLeastOneRole: (v) =>
    (!!v && v.length > 0) || i18n.t("validation.at_least_one_role_is_required"),

  atLeastOneAlphanumeric: (v) =>
    /([^a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\d])+([a-zA-Z\d])+|([a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\d])+([^a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\d])+/.test(
      v,
    ) ||
    i18n.t("validation.at_least_one_non_alphanumeric_character_is_required"),

  atLeastOneLowerCase: (v) =>
    /[a-zàâçéèêëîïôûùüÿñæœ]+/.test(v) ||
    i18n.t("validation.at_least_one_lowercase_letter_is_required"),

  atLeastOneUppercase: (v) =>
    /[A-ZÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+/.test(v) ||
    i18n.t("validation.at_least_one_uppercase_letter_is_required"),

  atLeastOneNumber: (v) =>
    /[0-9]+/.test(v) || i18n.t("validation.at_least_one_number_is_required"),

  canadianBankInstitutionNumber: (v) =>
    /^[0-9]{3}$/.test(v) || i18n.t("validation.institution_number"),

  canadianBankAccountNumber: (v) =>
    /^[0-9]{7,12}$/.test(v) || i18n.t("validation.account_number"),

  canadianBankTransitNumber: (v) =>
    /^[0-9]{5}$/.test(v) || i18n.t("validation.transit_number"),

  shortMonth: (v) =>
    v.toString() === "01" ||
    v.toString() === "02" ||
    v.toString() === "03" ||
    v.toString() === "04" ||
    v.toString() === "05" ||
    v.toString() === "06" ||
    v.toString() === "07" ||
    v.toString() === "08" ||
    v.toString() === "09" ||
    v.toString() === "10" ||
    v.toString() === "11" ||
    v.toString() === "12" ||
    i18n.t(""),

  numericString: (v) =>
    /^[0-9]{2}$/.test(v) || i18n.t("validation.numeric_string"),

  dynamic: {
    maxValue: (max) => {
      return (v) => {
        //console.log('max')
        if (v) {
          var val =
            (!!v && parseFloat(v) && parseFloat(v) <= max) ||
            i18n.t("validation.maximum_value_{0}", [max]);
          //console.log(val)
          return val;
        }
        return true;
      };
    },

    length: (len) => {
      return (v) => {
        if (v) {
          var parts = v.toString().split("");
          var val =
            (!!v && parts.length === len) ||
            i18n.t("validation.length_{0}", [len]);
          return val;
        }
        return true;
      };
    },

    minimumCharacters: (characters) => {
      return (v) =>
        (v && v.length >= characters) ||
        i18n.t("validation.minimum_{0}_characters_are_required", [characters]);
    },

    passwordsMatch: (password) => {
      return (v) => {
        return (
          (v && v === password) || i18n.t("validation.passwords_do_not_match")
        );
      };
    },

    certificateDigits: (number) => {
      switch (number) {
        case 9:
          return (v) =>
            !v || /^[\d]{9}$/.test(v) || i18n.t("validation.numeric_9");
        case 10:
          return (v) =>
            !v || /^[\d]{10}$/.test(v) || i18n.t("validation.numeric_10");
        case 11:
          return (v) =>
            !v || /^[\d]{11}$/.test(v) || i18n.t("validation.numeric_11");
        default:
          return (v) =>
            !v || /^[\d]{9}$/.test(v) || i18n.t("validation.numeric_9");
      }
    },
  },

  get passwordRules() {
    return [
      //this.required,
      this.dynamic.minimumCharacters(8),
      this.atLeastOneAlphanumeric,
      this.atLeastOneLowerCase,
      this.atLeastOneUppercase,
      this.atLeastOneNumber,
    ];
  },
  get usernameRules() {
    return [
      this.required,
      this.dynamic.minimumCharacters(8),
      this.atLeastOneAlphanumeric,
    ];
  },
  sin: (v) => {
    if (v === null || v === undefined || v === "") {
      return true;
    }
    let digits = v.toString().split("");
    if (digits.length !== 9) {
      return i18n.t("validation.sin");
    }
    let multiplications = [];
    let sum = 0;
    for (let i = 0; i < digits.length; i++) {
      if (i % 2 === 0) {
        multiplications[i] = parseInt(digits[i]);
      } else {
        multiplications[i] = parseInt(digits[i]) * 2;
        let multiplied = multiplications[i].toString().split("");
        if (multiplied.length === 2) {
          multiplications[i] =
            parseInt(multiplied[0]) + parseInt(multiplied[1]);
        }
      }
      sum += parseInt(multiplications[i]);
    }
    return sum % 10 === 0 || i18n.t("validation.sin");
  },
  certificate: (v) =>
    !v || /^[\d]{9}$/.test(v) || i18n.t("validation.numeric_9"), //correct to numeric string exactly 9 chars
};
const rules = {
  install(Vue) {
    Vue.prototype.$rules = validationRules;
  },
};

export default rules;
