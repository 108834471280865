<template>
  <section class="pdf-content">
    <section class="report-body">
      <!-- titlehead -->
      <section class="report-info">
        <v-row justify="space-between">
          <!-- logo -->
          <v-col cols="6">
            <img
              v-if="$vuetify.lang.current === 'fr'"
              class="user-image"
              :src="require('@/assets/CCWIPP-primary-logo.png')"
              alt="Logo"
              ref="logo"
            />
            <img
              v-else
              class="user-image"
              :src="require('@/assets/CCWIPP-primary-logo.png')"
              alt="Logo"
              ref="logo"
            />
          </v-col>

          <!-- contact us -->
          <v-col cols="1"></v-col>
          <v-col cols="5">
            <v-row no-gutter class="text-left">
              <v-col cols="6" class="title-text">{{
                $t("globals.contactUs")
              }}</v-col>
            </v-row>
            <v-row
              no-gutters
              class="text-left"
              v-for="(p, i) in Object.entries(reportData?.ContactUs)"
              :key="i"
            >
              <v-col cols="5" style="font-size: 12px" v-if="p[1]"
                >{{ p[0] }}:</v-col
              >
              <v-col cols="7" style="font-size: 12px" v-if="p[1]"
                ><strong>{{ p[1] }}</strong></v-col
              >
            </v-row>
            <v-row no-gutters class="text-left pt-2">
              <v-col cols="5" style="font-size: 12px"
                >{{ $t("globals.memberId") }}:</v-col
              >
              <v-col cols="7" style="font-size: 12px">
                <strong>{{ reportData?.MemberId }}</strong>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>
      <!-- Name and Address -->
      <v-row
        no-gutters
        class="text-left"
        style="font-size: 14px; padding: 30px 0px"
      >
        <v-col cols="12">{{ reportData?.MemberFullName }}</v-col>
        <v-col cols="12">{{ reportData?.AddressLine1 }}</v-col>
        <v-col cols="12">{{ reportData?.AddressLine2 }}</v-col>
      </v-row>

      <!-- Statement title -->
      <v-row
        class="text-left"
        no-gutters
        style="font-size: 22px; padding-bottom: 12px"
      >
        {{ $t("components.pension_statement.title") }}
      </v-row>
      <v-row no-gutters class="text-left">
        <v-col cols="12" style="font-size: 14px">
          <span>{{ $t("globals.for") }} </span>
          <span
            :style="`color: ${$vuetify.theme.themes.light.primary}; font-weight: 600`"
          >
            {{ `${reportData?.MemberFullName}` }}
          </span>
          <span>
            {{ `${$t("globals.as_of")} ${reportData?.StatementDate}` }}
          </span>
        </v-col>
      </v-row>

      <!-- your pension -->
      <v-row no-gutters style="padding: 20px 0px">
        <v-col cols="8">
          <v-row
            no-gutter
            class="text-left"
            style="font-size: 22px; padding-bottom: 8px"
          >
            <v-col cols="6" class="title-text">{{
              $t("components.pension_statement.your_pension")
            }}</v-col>
          </v-row>
          <v-row
            no-gutters
            class="text-left"
            v-for="(p, i) in Object.entries(reportData?.YourPension)"
            :key="i"
          >
            <v-col
              cols="6"
              style="font-size: 12px; word-spacing: 0.2em"
              v-if="p[1]"
              >{{ p[0] }}:</v-col
            >
            <v-col
              cols="4"
              style="font-size: 12px; text-align: end; padding-right: 60px"
              v-if="p[1]"
              ><strong>{{ p[1] }}</strong></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="4" class="primary text-center">
          <v-col
            class="pb-0"
            style="color: white; font-size: 42px; font-weight: 600"
            >${{ reportData?.EstimatedMonthlyPension }}</v-col
          >
          <v-col class="pt-0" style="color: white; font-weight: 600">{{
            $t("components.pension_statement.estimated_monthly_pension")
          }}</v-col>
        </v-col>
      </v-row>

      <div class="hr-divider"></div>
      <!-- About you -->
      <v-row no-gutters style="margin: 20px 0px; word-spacing: 0.2em">
        <v-col
          cols="12"
          class="text-left"
          style="font-size: 22px; padding-left: 0"
        >
          <v-col cols="12" class="title-text" style="padding-left: 0">{{
            $t("components.pension_statement.about_you")
          }}</v-col>
        </v-col>
        <v-col cols="6">
          <v-row
            no-gutters
            class="text-left"
            v-for="(p, i) in Object.entries(reportData?.AboutYouLeft)"
            :key="i"
          >
            <v-col style="font-size: 12px" v-if="p[1]">{{ p[0] }}:</v-col>
            <v-col style="font-size: 12px" v-if="p[1]"
              ><strong>{{ p[1] }}</strong></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row
            no-gutters
            class="text-left"
            v-for="(p, i) in Object.entries(reportData?.AboutYouRight)"
            :key="i"
          >
            <v-col cols="4" style="font-size: 12px" v-if="p[1]">
              {{ p[0] }}:
            </v-col>
            <v-col
              v-if="p[1]"
              cols="6"
              style="font-size: 12px; text-align: left; max-width: 200px"
              ><strong>{{ p[1] }}</strong></v-col
            >
          </v-row>
        </v-col>
      </v-row>

      <div class="hr-divider"></div>
      <!-- Details of Pension Earned -->
      <v-row no-gutters style="margin: 20px 0px">
        <v-col
          cols="12"
          class="text-left"
          :style="`font-size: 22px; padding-left: 0; color: ${$vuetify.theme.themes.light.primary}`"
        >
          <v-col cols="12" class="title-text" style="padding-left: 0">{{
            $t("components.pension_statement.details_of_pension_earned")
          }}</v-col>
        </v-col>
        <v-col cols="12" class="note-text">
          {{ $t("components.pension_statement.details_note") }}
        </v-col>
        <v-simple-table dense class="simple-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="63%"></th>
                <th width="22%">
                  {{ $t("components.pension_statement.credited_service") }}
                </th>
                <th colspan="2" width="20%">
                  {{ $t("components.pension_statement.estimated_pension") }}
                </th>
              </tr>
              <tr>
                <th width="63%"></th>
                <th width="22%">
                  {{ $t("components.pension_statement.in_years") }}
                </th>
                <th width="10%">
                  {{ $t("components.pension_statement.monthly") }}
                </th>
                <th width="10%">
                  {{ $t("components.pension_statement.annual") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in reportData?.PensionEarned" :key="i">
                <td style="text-align: start !important">{{ item.Cell1 }}</td>
                <td>{{ item.Cell2 }}</td>
                <td>${{ item.Cell3 }}</td>
                <td>${{ item.Cell4 }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>

      <div class="hr-divider"></div>

      <!-- Keep us Posted -->
      <v-row no-gutters style="margin: 20px 0px">
        <v-col cols="2">
          <qrcode-vue
            :value="
              $vuetify.lang.current === 'fr'
                ? 'rrecc.ca/connecter'
                : 'ccwipp.ca/login'
            "
            size="80"
            level="H"
          />
        </v-col>
        <v-col
          cols="10"
          class="text-left"
          style="font-size: 22px; padding-left: 0"
        >
          <v-col cols="12" class="title-text" style="padding: 0">{{
            $t("components.pension_statement.keep_us_posted")
          }}</v-col>
          <v-col cols="12" class="note-text" style="padding-left: 0">
            {{ $t("components.pension_statement.keep_us_posted.note") }}
            <span
              :style="`color: ${$vuetify.theme.themes.light.primary}; font-weight: 600`"
              >{{
                $vuetify.lang.current === "fr"
                  ? "rrecc.ca/connecter"
                  : "ccwipp.ca/login"
              }}</span
            >.
          </v-col>
        </v-col>
      </v-row>

      <section id="page2el">
        <!-- Member Contributions -->
        <v-row no-gutters style="margin: 0 0 10px 0">
          <v-col
            cols="12"
            class="text-left"
            :style="`font-size: 22px; padding-left: 0; color: ${$vuetify.theme.themes.light.primary}`"
          >
            <v-col
              cols="12"
              class="title-text"
              style="padding-left: 0; padding-top: 0"
              >{{
                $t("components.pension_statement.member_contributions")
              }}</v-col
            >
          </v-col>
          <v-col cols="12" class="note-text">
            {{ $t("components.pension_statement.member_contributions.note") }}
          </v-col>
          <v-simple-table dense class="simple-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="80%"></th>
                  <th width="20%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) in reportData?.MemberContributions"
                  :key="i"
                >
                  <td style="text-align: start !important">{{ item.Cell1 }}</td>
                  <td>${{ item.Cell2 }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-row>

        <div class="hr-divider"></div>

        <!-- Disclosures -->
        <v-row no-gutters style="margin: 0">
          <v-col
            cols="12"
            class="text-left"
            :style="`font-size: 22px; padding-left: 0; color: ${$vuetify.theme.themes.light.primary}`"
          >
            <v-col cols="12" class="title-text" style="padding-left: 0">{{
              $t("components.pension_statement.disclosures")
            }}</v-col>
          </v-col>
          <v-row no-gutters>
            <v-col cols="6">
              <v-col
                style="padding: 0 16px 0 0"
                v-for="(d, i) in reportData?.Disclosures?.Left"
                :key="`d-${i}`"
              >
                <v-col class="disclosure-title">{{
                  $t(`components.pension_statement.disclosures.${d}`)
                }}</v-col>
                <v-col class="disclosure">{{
                  $t(`components.pension_statement.disclosures.${d}.text`)
                }}</v-col>
              </v-col>
            </v-col>
            <v-col cols="6">
              <v-col
                style="padding: 0 0 0 16px"
                v-for="(d, i) in reportData?.Disclosures?.Right"
                :key="`d-${i}`"
              >
                <v-col class="disclosure-title">{{
                  $t(`components.pension_statement.disclosures.${d}`)
                }}</v-col>
                <v-col class="disclosure">{{
                  $t(`components.pension_statement.disclosures.${d}.text`)
                }}</v-col>
              </v-col>
            </v-col>
          </v-row>
        </v-row>
      </section>
    </section>
  </section>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
  },
  props: {
    reportData: {
      typr: Object,
      default: () => {},
    },
    memberStatus: String,
  },
  data() {
    return {
      footerAddresses: [
        {
          line1: "110-61 International Boulevard",
          line2: "Toronto, Ontario M9W 6K4",
          line3: "contact@ccwipp.ca",
          line4: "1-888-7CCWIPP",
          line5: "ccwipp.ca",
        },
        {
          line1: "201-1200, boul. Crémazie Est",
          line2: "Montréal, QC  H2P 3A5",
          line3: "contactez@rrecc.ca",
          line4: "1-888-97RRECC",
          line5: "rrecc.ca",
        },
      ],
    };
  },
  computed: {
    table1Names() {
      return this.getColumnNames(this.reportData.Table1);
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  },
  methods: {
    getColumnNames(data) {
      const names = new Set();
      for (const row of data) {
        for (const key of Object.keys(row)) {
          names.add(key);
        }
      }
      return names;
    },
  },
};
</script>

<style lang="scss" scoped>
th {
  color: red !important;
}

.primary {
  background-color: red;
}
.pdf-content {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  width: 100%;
  background: #fff;

  .report-body {
    .report-info {
      display: flex;

      padding-bottom: 0px;
      justify-content: space-between;

      .image-container {
        width: 350px;
        height: 80px;
        overflow: hidden;
        position: relative;
        margin-right: 25px;
      }
      .user-image {
        max-width: 350px;
        height: auto;
      }

      .user-details {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        font-size: 18px;

        .user-name {
          margin-bottom: 10px;
          flex-shrink: 0;
        }

        .detail-container {
          flex-shrink: 0;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .detail-label {
            font-weight: 600;
            margin-right: 10px;
          }
          .detail-text {
            font-size: 13px;
          }
        }
      }
    }

    .hr-divider {
      border-bottom: 1px solid rgb(163, 163, 163);
    }

    .report-title {
      font-size: 18px;
      font-weight: 400;
      margin: 10px 0 5px 0;
    }
    .report-note {
      font-size: 14px;
      font-weight: 400;
      margin: 5px 0 5px 0;
    }

    .report-subtitle {
      font-size: 16px;
      font-weight: 400;
      margin: 5px 0;
    }
  }
}
</style>
<style scoped>
.table >>> th {
  background-color: red !important;
  color: white !important;
  border: 1px solid rgb(187, 187, 187);
  height: 25px !important;
}
.simple-table {
  width: 100%;
}
.simple-table >>> th {
  color: black !important;
  border: none !important;
  height: 0px !important;
  text-align: center !important;
}
.simple-table >>> td {
  color: black !important;
  border: none !important;
  height: 20px !important;
  text-align: center !important;
  font-weight: 400;
  padding: 0 !important;
  word-spacing: 0.2em !important;
}
.table >>> td {
  border: 1px solid rgb(187, 187, 187);
  height: 30px !important;
  text-align: center;
}
.footer-image {
  max-width: 150px;
  height: auto;
  margin-left: 50%;
  transform: translateX(-50%);
}
.report-footer {
  margin-top: 20px;
  padding-right: 5px;
}
p {
  font-size: 12px;
  margin: 10px 0;
  text-align: justify;
}
.address {
  font-size: 12px;
  text-align: left;
  padding-bottom: 6px;
  padding-top: 4px;
}
.address-image {
  font-size: 12px;
  text-align: left;
  margin: auto;
}
.custom-underline {
  text-decoration: none;
  padding-bottom: 0px;
  border-bottom: 1px solid;
  cursor: pointer;
}
.title-text {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 16px;
  color: #4472cf;
  word-spacing: 0.2em !important;
}
.note-text {
  font-size: 12px;
  text-align: justify;
  padding-bottom: 16px !important;
  word-spacing: 0.2em !important;
}
.disclosure-title {
  font-size: 14px;
  font-weight: 600;
  padding-top: 16px;
  text-align: left;
  padding: 0;
  word-spacing: 0.2em !important;
}
.disclosure {
  font-size: 12px;
  text-align: left;
  padding: 0 0 16px 0;
  word-spacing: 0.1em !important;
}
</style>
